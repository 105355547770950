import "./OmokChat.css";
import React, { useEffect, useState } from "react";
//import { Grid, ListItem, ListItemText, Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import ScrollToBottom from "react-scroll-to-bottom";

function OmokChat({ socket, username, room, player }) {
    const [currentMessage, setCurrentMessage] = useState("");
    const [messageList, setMessageList] = useState([]);

    const sendMessage = async () => {
        if (currentMessage !== "") {
            const messageData = {
                room: room,
                author: username,
                player: player,
                message: currentMessage,
                time:
                    addZeroBefore(new Date(Date.now()).getHours()) +
                    ":" +
                    addZeroBefore(new Date(Date.now()).getMinutes()),
            };

            await socket.emit("send_message", messageData);
            setMessageList((list) => [...list, messageData]);
            setCurrentMessage("");
        }
    };

    const addZeroBefore = (num) => {
        return (num < 10 ? "0" : "") + num;
    };

    useEffect(() => {
        socket.on("receive_message", (data) => {
            data.time =
                addZeroBefore(new Date(Date.now()).getHours()) +
                ":" +
                addZeroBefore(new Date(Date.now()).getMinutes());
            setMessageList((list) => [...list, data]);
        });
    }, [socket]);

    return (
        <div>
            <Grid
                container
                alignItems="stretch"
                justifyContent="flex-start"
                spacing={0}
            >
                <Grid item xs={12}>
                    <div className="chat-window">
                        <div className="chat-header">
                            <p>Live Chat</p>
                        </div>
                        <div className="chat-body">
                            <ScrollToBottom className="message-container">
                                {messageList.map((messageContent) => {
                                    return (
                                        <div
                                            key={
                                                messageContent +
                                                ":" +
                                                messageContent.author
                                            }
                                            className="message"
                                            id={
                                                messageContent.player === 0
                                                    ? "system"
                                                    : player ===
                                                      messageContent.player
                                                    ? "you"
                                                    : "other"
                                            }
                                        >
                                            <div>
                                                <div className="message-content">
                                                    <p>
                                                        {messageContent.message}
                                                    </p>{" "}
                                                </div>
                                                {messageContent.player ===
                                                0 ? null : (
                                                    <div className="message-meta">
                                                        <p id="time">
                                                            {
                                                                messageContent.time
                                                            }
                                                        </p>
                                                        <p id="author">
                                                            {
                                                                messageContent.author
                                                            }
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </ScrollToBottom>
                        </div>

                        <div className="chat-footer">
                            <input
                                type="text"
                                value={currentMessage}
                                placeholder="Hey.."
                                onChange={(event) => {
                                    setCurrentMessage(event.target.value);
                                }}
                                onKeyPress={(event) => {
                                    event.key === "Enter" && sendMessage();
                                }}
                            />
                            <button onClick={sendMessage}>&#9658;</button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default OmokChat;
