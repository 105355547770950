import React, { Component } from "react";
import Auxi from "../../hoc/Auxi";
import "bootstrap/dist/css/bootstrap.min.css";
import OmokBundle from "../../components/OmokBundle/OmokBundle";
import { Grid, List, ListItem, ListItemText } from "@material-ui/core";

class Omok extends Component {
    state = {};
    render() {
        return (
            <Auxi>
                <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={1} />
                    <Grid item xs={10} align="center">
                        <h1 className="display-3">Omok</h1>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                        This an advanced version of Tic-Tac-Toe where the first
                        to get exactly 5 in a horizontal, vertical, diagonal row
                        wins. Create a room for you and friend and than receive
                        a Room Code which your friend can use to join your room
                        where you can both play and chat together.
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={0} sm={0} md={1} />
                    <Grid item xs={12} sm={12} md={10} align="center">
                        <br />
                        <OmokBundle />
                    </Grid>
                    <Grid item xs={0} sm={0} md={1} />
                </Grid>
            </Auxi>
        );
    }
}

export default Omok;
