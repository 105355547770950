import React from "react";
import { ListGroup } from "react-bootstrap";

const savedList = () => (
    <ListGroup>
        <ListGroup.Item action variant="light">
            Saved Counts:
        </ListGroup.Item>
        <ListGroup.Item action variant="light"></ListGroup.Item>
        <ListGroup.Item action variant="light"></ListGroup.Item>
        <ListGroup.Item action variant="light"></ListGroup.Item>
        <ListGroup.Item action variant="light"></ListGroup.Item>
    </ListGroup>
);

export default savedList;
