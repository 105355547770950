import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { TextField } from "@material-ui/core";
import {
    Grid,
    Paper,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
} from "@mui/material";
import OmokChat from "./OmokChat/OmokChat";
import OmokGame from "./OmokGame/OmokGame";

const connectionOptions = {
    reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket", "polling", "flashsocket"],
    "force new connection": true,
};

// const socket = io.connect("http://localhost:3001");

// const socket = io.connect("http://omokserver.herokuapp.com", {
//     transports: ["websocket", "polling", "flashsocket"],
// });
const socket = io.connect("http://omokserver.metleya.com:8080", {
    transports: ["websocket", "polling", "flashsocket"],
});

function OmokBundle() {
    const [username, setUsername] = useState("");
    const [room, setRoom] = useState("");
    const [player, setPlayer] = useState("");
    const [usernameCreate, setUsernameCreate] = useState("");
    const [roomCreate, setRoomCreate] = useState("");
    const [showChat, setShowChat] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [roomPrompt, setRoomPrompt] = useState({
        title: "title_error",
        message: "message_error",
    });

    const [roomErrorOpen, setRoomErrorOpen] = useState(false);
    const [roomError, setRoomError] = useState({
        title: "title_error",
        message: "message_error",
    });

    const [endPromptOpen, setEndPromptOpen] = useState(false);
    const [endPrompt, setEndPrompt] = useState("Error");

    const [seconds, setSeconds] = useState(0);
    const [turn, setTurn] = useState(0);

    const [player1Ready, setPlayer1Ready] = useState(false);
    const [player2Ready, setPlayer2Ready] = useState(false);
    const [gameActive, setGameActive] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRoomErrorOpen = () => {
        setRoomErrorOpen(true);
    };
    const handleRoomErrorClose = () => {
        setRoomErrorOpen(false);
    };

    const joinRoom = () => {
        if (room !== "") {
            const data = { code: room, author: username };
            socket.emit("join_room", data);
            //socket.emit("join_room", room);

            handleClose();
        }
    };

    const createRoom = () => {
        const room = "00000";
        if (username !== "") {
            socket.emit("create_room", room);
            handleClose();
        }
    };

    const playerReady = () => {
        socket.emit("player_ready");
    };

    const startGame = () => {
        socket.emit("start_game");
    };

    const leaveRoom = () => {
        const data = { code: room, author: username };
        socket.emit("leave_room", data);
        setShowChat(false);
    };

    useEffect(() => {
        socket.on("room_joined", (data) => {
            setPlayer(data);
            setShowChat(true);
        });
        socket.on("game_code", (data) => {
            setRoom(data);
        });
        socket.on("game_started", (data) => {
            setGameActive(true);
        });
        socket.on("player_ready", (data) => {
            setPlayer1Ready(data.player1);
            setPlayer2Ready(data.player2);

            // if (data === 1) {
            //     const flip = !player1Ready;
            //     setPlayer1Ready(flip);
            // } else {
            //     const flip = !player2Ready;
            //     setPlayer2Ready(flip);
            // }
        });
        socket.on("game_over", (data) => {
            setGameActive(false);
            setPlayer1Ready(false);
            setPlayer2Ready(false);

            if (data === 5) {
                if (player === 1) {
                    setEndPrompt("WINNER");
                } else {
                    setEndPrompt("LOSER");
                }
            } else if (data === -5) {
                if (player === -1) {
                    setEndPrompt("WINNER");
                } else {
                    setEndPrompt("LOSER");
                }
            }

            setEndPromptOpen(true);
        });
        socket.on("game_draw", (data) => {
            setGameActive(false);
            setPlayer1Ready(false);
            setPlayer2Ready(false);
            setEndPrompt("DRAW");

            setEndPromptOpen(true);
        });
        socket.on("update_board", (data) => {
            setTurn(data);
            setSeconds(15);
        });
        socket.on("room_full", (data) => {
            setRoomError({
                title: "Room Full",
                message: "The room " + data + " is full",
            });
            setRoomErrorOpen(true);
            console.log("Room is Full");
        });
        socket.on("room_not_exists", (data) => {
            setRoomError({
                title: "Room not Found",
                message:
                    "The room " +
                    data +
                    " does not exist, please check whether you have typed in the correct room code.",
            });
            setRoomErrorOpen(true);
            console.log("Room does not exist");
        });
        socket.on("room_exists", (data) => {
            console.log("Room ID already used");
        });
        socket.on("connect_error", (err) => {
            console.log(`connect_error due to ${err.message}`);
        });
    });

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(myInterval);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    const handleRoomCreate = () => {
        handleClickOpen();
        setRoomPrompt({ title: "Create Room", message: "Create" });
    };

    const handleRoomJoin = () => {
        handleClickOpen();
        setRoomPrompt({ title: "Join Room", message: "Join" });
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{roomPrompt.title}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        fullWidth
                        onChange={(event) => {
                            setUsername(event.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={
                            roomPrompt.message === "Create"
                                ? createRoom
                                : joinRoom
                        }
                    >
                        {roomPrompt.message}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={roomErrorOpen} onClose={handleRoomErrorClose}>
                <DialogTitle>{roomError.title}</DialogTitle>
                <DialogContent>
                    <Typography variant="h7" component="div" gutterBottom>
                        {roomError.message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleRoomErrorClose}>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={endPromptOpen}
                onClose={() => setEndPromptOpen(false)}
            >
                <DialogContent>
                    <Typography variant="h3" component="div" gutterBottom>
                        {endPrompt}
                    </Typography>
                </DialogContent>
            </Dialog>

            {!showChat ? (
                <Grid
                    container
                    spacing={1}
                    alignItems="flex-start"
                    sx={{
                        width: { sx: "100%", lg: "75%" },

                        justifyContent: "center",
                    }}
                    justifyContent="center"
                >
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={8}
                            sx={{
                                bgcolor: "background.paper",

                                borderRadius: 3,
                                p: 2,
                                maxWidth: 500,
                            }}
                        >
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    component="div"
                                    gutterBottom
                                >
                                    How to Play
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    component="div"
                                    gutterBottom
                                >
                                    1. Player who first successfully place 5
                                    Omok pieces in a horizontal, vertical, or
                                    diagonal row wins.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    component="div"
                                    gutterBottom
                                >
                                    2. Player who first begins the game will be
                                    determined randomly.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    component="div"
                                    gutterBottom
                                >
                                    3. There is the time limit of 10 seconds for
                                    each turn. Once you exceed the time limit,
                                    your turn will be skipped.
                                </Typography>
                            </Grid>
                        </Paper>
                        <br />
                        <Paper
                            elevation={8}
                            sx={{
                                bgcolor: "background.paper",
                                display: { xs: "none", md: "block" },
                                borderRadius: 3,
                                p: 2,
                                maxWidth: 500,
                            }}
                        >
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    component="div"
                                    gutterBottom
                                >
                                    Coming Soon:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    component="div"
                                    gutterBottom
                                >
                                    &#8226; Updated UI/Graphics
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    component="div"
                                    gutterBottom
                                >
                                    &#8226; Update Rules
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    component="div"
                                    gutterBottom
                                >
                                    &#8226; Open Rooms
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6} spacing={2}>
                        <Paper
                            elevation={8}
                            sx={{
                                bgcolor: "background.paper",

                                borderRadius: 3,
                                p: 2,
                                maxWidth: 500,
                            }}
                        >
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    component="div"
                                    gutterBottom
                                >
                                    Create a Room
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    gutterBottom
                                >
                                    Create a room and receive a room code to
                                    share with your friends to play together.
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Button
                                    onClick={handleRoomCreate}
                                    color="secondary"
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        p: 2,
                                    }}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Paper>
                        <br />
                        <Typography variant="h5" component="div" gutterBottom>
                            OR
                        </Typography>
                        <br />

                        <Paper
                            elevation={8}
                            sx={{
                                bgcolor: "background.paper",

                                borderRadius: 3,
                                p: 2,
                                maxWidth: 500,
                            }}
                        >
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    component="div"
                                    gutterBottom
                                >
                                    Join a Room
                                </Typography>
                            </Grid>

                            <Grid item>
                                <TextField
                                    id="outlined-basic"
                                    label="Room"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setRoom(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleRoomJoin}
                                    fullWidth
                                    color="secondary"
                                    variant="outlined"
                                    sx={{
                                        marginTop: 2,
                                        p: 2,
                                    }}
                                >
                                    Join
                                </Button>
                            </Grid>
                        </Paper>
                        <br />
                        <Paper
                            elevation={8}
                            sx={{
                                bgcolor: "background.paper",
                                display: { xs: "block", md: "none" },
                                borderRadius: 3,
                                p: 2,
                                maxWidth: 500,
                            }}
                        >
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    component="div"
                                    gutterBottom
                                >
                                    Coming Soon:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    component="div"
                                    gutterBottom
                                >
                                    &#8226; Updated UI/Graphics
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    component="div"
                                    gutterBottom
                                >
                                    &#8226; Update Rules
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    align="left"
                                    component="div"
                                    gutterBottom
                                >
                                    &#8226; Open Rooms
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            ) : (
                <div>
                    <Paper elevation={8}>
                        <Grid container spacing={0} alignItems="center">
                            <Grid
                                container
                                justifyContent="space-between"
                                item
                                xs={8}
                                lg={7}
                            >
                                <Grid item xs={4}>
                                    <Typography
                                        variant="h7"
                                        component="div"
                                        align="left"
                                        sx={{ paddingLeft: 1 }}
                                    >
                                        Room Code:
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h7" component="div">
                                        Turn#
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography
                                        variant="h7"
                                        component="div"
                                        align="right"
                                        sx={{ paddingRight: 1 }}
                                    >
                                        Time:
                                    </Typography>
                                </Grid>
                                <Grid container item xs={4}>
                                    <Grid item>
                                        <Tooltip title="Copy" followCursor>
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                align="left"
                                                flex={0}
                                                sx={{
                                                    marginLeft: 3,
                                                }}
                                                onClick={() =>
                                                    navigator.clipboard.writeText(
                                                        room
                                                    )
                                                }
                                            >
                                                {room}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="h5" component="div">
                                        {turn}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        align="right"
                                        sx={{ marginRight: 3 }}
                                    >
                                        {seconds}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} lg={2.5}>
                                <Paper>
                                    <Typography>Player 1</Typography>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color={
                                            player1Ready ? "success" : "error"
                                        }
                                        disabled={gameActive}
                                        onClick={
                                            player === 1 ? playerReady : null
                                        }
                                    >
                                        {player1Ready ? "Ready" : "Not Ready"}
                                    </Button>
                                </Paper>
                            </Grid>
                            <Grid item xs={2} lg={2.5}>
                                <Paper>
                                    <Typography>Player 2</Typography>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color={
                                            player2Ready ? "success" : "error"
                                        }
                                        disabled={gameActive}
                                        onClick={
                                            player === -1 ? playerReady : null
                                        }
                                    >
                                        {player2Ready ? "Ready" : "Not Ready"}
                                    </Button>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            alignItems="flex-start"
                            justify="center"
                        >
                            <Grid item xs={12} sm={8} md={8} lg={7}>
                                <OmokGame
                                    socket={socket}
                                    username={username}
                                    room={room}
                                    player={player}
                                    time={seconds}
                                />
                            </Grid>
                            <Grid container item xs={12} sm={4} md={4} lg={5}>
                                <Grid item xs={12}>
                                    <OmokChat
                                        socket={socket}
                                        username={username}
                                        room={room}
                                        player={player}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="error"
                                        onClick={leaveRoom}
                                    >
                                        Leave
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        disabled={
                                            player1Ready === false ||
                                            player2Ready === false ||
                                            player === -1 ||
                                            gameActive
                                        }
                                        variant="contained"
                                        color="success"
                                        onClick={startGame}
                                    >
                                        Start
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <br />
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            )}
        </div>
    );
}

export default OmokBundle;
