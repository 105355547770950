import React, { useEffect, useState } from "react";
// import { Paper, Typography } from "@mui/material";
import { Grid, Box, Typography, Dialog, DialogContent } from "@mui/material";
import mushroomPiece from "../../../assets/images/Omok/GamePieces/mushroom_piece_x.png";
import slimePiece from "../../../assets/images/Omok/GamePieces/slime_piece_x.png";
import blankPiece from "../../../assets/images/Omok/GamePieces/blank_piece_x.png";
import omokBoard from "../../../assets/images/Omok/GamePieces/omok_board.png";

function OmokGame({ socket, username, room, player, time }) {
    const [turn, setTurn] = useState(false);
    const [open, setOpen] = React.useState(false);

    const [pieceSize, setPieceSize] = useState("3vw");

    const [gameState, setGameState] = useState({
        gameBoard: [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ],
        player1Ready: false,
        player2Ready: false,
        turn: 1,
        row: 0,
        col: 0,
    });
    const [gameBoard, setGameBoard] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ]);

    useEffect(() => {
        socket.on("next_turn", (data) => {
            if (player === data) {
                handleClickOpen();
                setTimeout(() => {
                    // After 3 seconds set the show value to false
                    handleClose();
                }, 1000);
                setTurn(true);
            }
        });
        socket.on("end_turn", (data) => {
            if (data === player) {
                setTurn(false);
            }
        });

        socket.on("update_state", (data) => {
            const state = { ...JSON.parse(data) };
            setGameState(state);
        });
    }, [socket]);

    useEffect(() => {
        updateState();
    }, [gameState]);

    const updateState = () => {
        //console.log(gameState);
        //console.log(gameState.gameBoard);
    };

    const placePiece = (row, col) => {
        const cord = { row: row, col: col };
        setTurn(false);
        socket.emit("place_piece", cord);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const displayPiece = (gameSpot, row, col) => {
        if (gameSpot === 1) {
            return (
                <Box
                    sx={{
                        width: "100%",
                        height: 0,
                        paddingBottom: "100%",
                        backgroundColor: "red",
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        color: "white",
                        objectFit: "cover",
                        textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF",
                        "&:hover": {
                            backgroundColor: "primary.main",
                            opacity: [0.9, 0.8, 0.7],
                        },
                    }}
                    onClick={() =>
                        console.log(gameSpot + "=" + row + " " + col)
                    }
                ></Box>
            );
        } else if (gameSpot === -1) {
            return (
                <Box
                    sx={{
                        width: "100%",
                        height: 0,
                        paddingBottom: "100%",
                        backgroundColor: "blue",
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        color: "white",
                        objectFit: "cover",
                        textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF",
                        "&:hover": {
                            backgroundColor: "primary.main",
                            opacity: [0.9, 0.8, 0.7],
                        },
                    }}
                    onClick={() =>
                        console.log(gameSpot + "=" + row + " " + col)
                    }
                ></Box>
            );
        } else {
            return (
                <Box
                    sx={{
                        width: "100%",
                        height: 0,
                        paddingBottom: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        color: "white",
                        objectFit: "cover",
                        textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF",
                        "&:hover": {
                            backgroundColor: "primary.main",
                            opacity: [0.9, 0.8, 0.7],
                        },
                    }}
                    onClick={
                        turn === true && time > 1
                            ? () => placePiece(row, col)
                            : null
                    }
                ></Box>
            );
        }
    };

    const displayCustomPiece = (gameSpot, row, col) => {
        if (gameSpot === 1) {
            return (
                <Box
                    sx={{
                        backgroundImage: `url(${mushroomPiece})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: { xs: "5vw", sm: "3vw" },
                        width: { xs: "5vw", sm: "3vw" },
                    }}
                ></Box>
            );
        } else if (gameSpot === -1) {
            return (
                <Box
                    sx={{
                        backgroundImage: `url(${slimePiece})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: { xs: "5vw", sm: "3vw" },
                        width: { xs: "5vw", sm: "3vw" },
                    }}
                ></Box>
            );
        } else {
            return (
                <Box
                    sx={{
                        backgroundImage: `url(${blankPiece})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height: { xs: "5vw", sm: "3vw" },
                        width: { xs: "5vw", sm: "3vw" },
                        "&:hover": {
                            backgroundColor: "primary.main",
                            opacity: [0.9, 0.8, 0.7],
                        },
                    }}
                    onClick={
                        turn === true && time > 0
                            ? () => placePiece(row, col)
                            : null
                    }
                ></Box>
            );
        }
    };

    const displayCustomBoard = () => {
        const output = [];

        gameState.gameBoard.forEach((row, rowIndex) => {
            output.push(
                row.forEach((col, colIndex) => {
                    output.push(displayCustomPiece(col, rowIndex, colIndex));
                })
            );
        });
        return output;
    };

    const displayBoard = () => {
        const output = [];

        gameState.gameBoard.forEach((row, rowIndex) => {
            output.push(
                row.forEach((col, colIndex) => {
                    output.push(
                        <Grid item key={rowIndex + ":" + colIndex} xs={1}>
                            {displayCustomPiece(col, rowIndex, colIndex)}
                        </Grid>
                    );
                })
            );
        });
        return output;
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Typography variant="h3" component="div" gutterBottom>
                        Your Turn
                    </Typography>
                </DialogContent>
            </Dialog>
            {/* <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={0}
                columns={15}
            >

                {displayBoard()}
            </Grid> */}

            {/* <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={0}
                columns={15}
            >
                {displayBoard()}
            </Grid> */}
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: { xs: "75vw", sm: "45vw" },
                    marginTop: { xs: "10px", sm: "0px" },
                    marginBottom: { xs: "10px", sm: "0px" },
                }}
            >
                {displayCustomBoard()}
            </Box>
        </div>
    );
}

export default OmokGame;
